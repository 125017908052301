<template>
  <div class="product-details">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
        </template>
        <template #title>
          <div class="navbar-title">Product details</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="content">
      <van-image width="100%" :src="require(`./img/${$route.query.id}.png`)" />
      <div class="bg"></div>
    </div>

    <div class="bottom-bar">
      <div class="bottom-bar__left">
        <div class="btn" @click="to_order">
          <div class="icon">
            <van-icon :name="require(`./img/order-icon.png`)" size="2.75rem" />
          </div>
          <div class="text">Order</div>
        </div>
        <div class="btn" @click="show1 = true">
          <div class="icon">
            <van-icon :name="require(`./img/share-icon.png`)" size="2.75rem" />
          </div>
          <div class="text">Share</div>
        </div>
      </div>
      <div class="bottom-bar__right" @click="show = true">
        <van-image height="100%" :src="require(`./img/buy.png`)" />
      </div>
    </div>

    <div class="modal-box">
      <van-action-sheet v-model="show">
        <div class="buy-content" @click="to_checkout">
          <van-image width="100%" :src="require(`./img/buy-img.png`)" />
          <div class="close" @click.stop="show = false"></div>
          <div class="bg"></div>
        </div>
      </van-action-sheet>

      <van-action-sheet v-model="show1">
        <div class="buy-content">
          <van-image width="100%" :src="require(`./img/share-img.png`)" />
          <div class="close" @click.stop="show1 = false"></div>
          <div class="bg" @click="to_chat"></div>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      show1: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    to_checkout() {
      this.$router.push({ path: "/checkout" });
    },
    to_chat() {
      this.$router.push({ path: "/chat" });
    },
    to_order() {
      this.$router.push({ path: "/orders" });
    },
  },
};
</script>

<style lang="scss">
.product-details {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .navbar-title {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      color: #000000;
      font-weight: bold;
      position: relative;
    }
  }

  .content {
    position: relative;
    padding-bottom: 8.125rem;

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .bottom-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    &__left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
          margin-bottom: 0.3125rem;
        }
        .text {
          font-size: 1.375rem;
          color: #777777;
          line-height: 1.625rem;
        }
      }
    }

    &__right {
      width: 28.25rem;
      height: 6.875rem;
      display: flex;
      align-items: center;
    }
  }
}

.buy-content {
  position: relative;
  .close {
    width: 5.25rem;
    height: 5.25rem;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 5;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.van-action-sheet {
  max-height: 90%;
}
</style>